<script setup lang="ts">
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const props = withDefaults(defineProps<{
  controller: ReturnType<typeof useModal>;
  size?: "small" | "default" | "large";
  testid?: string
}>(), {
  size: "default",
});

const { controller } = toRefs(props);
const { isOpen, close } = controller.value;

const { escape } = useMagicKeys();

const modalWindow = ref();

onClickOutside(modalWindow, () => close());

watch(escape, () => {
  isOpen.value && close();
});

watch(isOpen, () => {
  if(isOpen.value) {
    nextTick(() => {
      disableBodyScroll(modalWindow.value);
    });
  }
  else {
    enableBodyScroll(modalWindow.value);
  }
});
</script>

<template>
  <Teleport to="body">
    <Transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="isOpen"
        class="cursor-close fixed inset-0 bg-maas-background-grey-normal backdrop-blur-lg bg-opacity-60 transition-all z-100 flex items-center justify-center"
        :data-testid="testid"
      >
        <Transition
          enter-active-class="transition ease-out duration-200 transform "
          enter-from-class="opacity-0 translate-y-full lg:translate-y-10 scale-95"
          enter-to-class="opacity-100 translate-y-0 scale-100"
          leave-active-class="ease-in duration-200"
          leave-from-class="opacity-100 translate-y-0 scale-100"
          leave-to-class="opacity-0 translate-y-full lg:translate-y-10 translate-y-0 scale-95"
        >
          <div
            v-if="isOpen"
            ref="modalWindow"
            class="relative shadow-2xl cursor-default w-full lg:max-w-[90vw] h-screen lg:h-auto max-h-[-webkit-fill-available] lg:max-h-[90vh] lg:rounded-lg bg-white overflow-y-auto"
            :class="{
              'lg:w-lg': size === 'small',
              'lg:w-2xl': size === 'default',
              'lg:w-6xl': size === 'large',
            }"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <header class="relative flex justify-between bg-maas-background-default sticky top-0 pl-4 md:pl-8 pr-4 py-4 z-1 gap-4">
              <h2 class="mt-4 text-h3 text-maas-typography-headline-primary w-full leading-tight">
                <slot name="title" />
              </h2>
              <slot name="close">
                <a
                  href="#"
                  @click="close"
                  class="hidden lg:block lg:shrink-0 lg:bg-maas-background-grey-medium lg:flex lg:h-8 lg:w-8 lg:items-center lg:justify-center lg:rounded-full lg:text-sm sm:right-6 sm:top-6"
                >✕</a>
              </slot>
            </header>

            <div class="px-4 md:px-8 pb-20 lg:pb-8">
              <slot />
            </div>
          </div>
        </Transition>

        <div class="z-5 fixed! lg:hidden flex bottom-0 left-0 right-0 gap-4 px-4 py-3 cursor-default">
          <div class="flex grow-1 items-center justify-center">
            <div class="w-full max-w-md">
              <slot name="mobileToolbar" />
            </div>
          </div>
          <div class="grow-0 shrink-0">
            <slot name="mobileClose">
              <LayoutNavigationBarButton @click="close" class="h-[52px]! w-[52px]! rounded-full border-px border-maas-border-light/10">
                <svg class="scale-60" height="48" viewBox="0 -960 960 960" width="48" xmlns="http://www.w3.org/2000/svg">
                  <path class="fill-current" d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                </svg>
              </LayoutNavigationBarButton>
            </slot>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
